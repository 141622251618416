import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../components/layout';
import FAQElement from '../../components/InfosPages/FAQElement';
import InfoButton from '../../components/InfosPages/StyledButton';
import ReturnLink from '../../components/InfosPages/ReturnLink';

const infos = require('../../FAQ.json');
const green = 'rgba(47, 137, 117, 1)';
const gray = '#FCFCFC';
const grayFont = 'rgba(122, 122, 122, 1)';

export default function FAQ() {
  let titles = [];
  if (typeof window !== 'undefined') {
    titles = Array.from(document.querySelectorAll('.Titles'));
  }

  const [openedFields, setOpenFields] = useState();

  useEffect(() => {
    if (openedFields) {
      titles.forEach((title) => {
        // console.log(title.id, openedFields);
        if (openedFields.includes(title.id)) {
          // console.log(title.id);
          title.style.color = green;
          title.style.fontWeight = 'bold';
        } else {
          title.style.color = grayFont;
          title.style.fontWeight = 'normal';
        }
      });
    }
  }, [openedFields]);

  const setEvaluateList = () => {
    setOpenFields(Array.from(document.querySelectorAll('.Open')).map((e) => e.id));
  };

  return (
    <Layout>
      <ReturnLink />
      <div
        className="MainWrapper / -lg:px-16 pb-36 flex flex-col items-start justify-start max-w-full px-3 mt-6"
        style={{ paddingBottom: '34rem' }}
      >
        <h1 className="my-5 text-2xl font-medium text-green-700">FAQ</h1>
        <hr className="background-green-700 w-full h-2" />
        <div className="flex flex-row flex-wrap items-start justify-between w-full px-5">
          <div className="-lg:w-1/3 -md:flex-col md:justify-between flex-nowrap flex flex-row w-full">
            <div className=" md:hidden flex flex-col">
              {infos.map((info, index) => (
                <nav
                  style={{ color: grayFont }}
                  className="Titles / my-2 text-lg"
                  key={index}
                  id={info.title}
                >
                  {infos.indexOf(info) + 1} - {info.title}
                </nav>
              ))}
            </div>
            <div className="Timeline / -md:hidden flex-nowrap flex flex-row items-center justify-between overflow-x-auto">
              <InfoButton className="-md:hidden text-sm" text={'Introduction'} />
              {infos.map((info, index) => (
                <InfoButton className="text-sm" text={info.title} key={index} />
              ))}
            </div>
          </div>
          <div className="-lg:w-7/12 md:z-10 flex flex-col w-full" style={{ backgroundColor: gray }}>
            {infos.map((info, index) => (
              <div key={index}>
                <FAQElement text={info.answer} title={info.title} setEvaluateList={setEvaluateList} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
