import React, { useEffect, useState } from 'react';

const gray = 'rgba(122, 122, 122, 1)';
const green = 'rgba(47, 137, 117, 1)';

const FAQElement = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    props.setEvaluateList();
  }, [isOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col border-0 border-b-2">
      <div
        className={`${
          isOpen ? 'Open' : ''
        } flex-nowrap flex flex-row items-center justify-between w-full p-3 border-gray-300 cursor-pointer`}
        onClick={handleClick}
        id={props.title}
      >
        <h3 className="font-bold"> {props.title}</h3>
        <span className={`${isOpen ? 'closeButton' : ''} font-bold text-2xl`} style={{ color: green }}>
          +
        </span>
      </div>
      {isOpen && (
        <div className="p-3 font-semibold" style={{ color: gray }}>
          <p dangerouslySetInnerHTML={{ __html: props.text }} />
        </div>
      )}
    </div>
  );
};

export default FAQElement;
